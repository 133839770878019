import { formatDdMmYyyy, getStartOfCalendarDate, PARIS, substractDurationToCalendarDate } from '@orus.eu/calendar-date'
import { PersistentNotification, Text } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { trpcReact } from '../../../client'

export const RenewalCustomerNotification = function RenewalCustomerNotification({
  className,
}: {
  className?: string
}): JSX.Element | undefined {
  const [renewals] = trpcReact.renewal.getUserPendingRenewalsToFill.useSuspenseQuery()
  const navigate = useNavigate()

  const navigateToRenewal = useCallback(
    (token: string) => {
      void navigate({ to: '/s/r/$token', params: { token } })
    },
    [navigate],
  )

  return (
    <div className={className}>
      {renewals.map((renewal) => {
        const effectLuxonDate = getStartOfCalendarDate(renewal.effectDate!, PARIS)
        const now = DateTime.now()

        const monthsLeft = effectLuxonDate.diff(now, 'months').months

        if (monthsLeft <= 1) return undefined

        return (
          <PersistentNotification
            actionButton={{
              label: 'Mettre à jour mon contrat',
              onClick: () => {
                navigateToRenewal(renewal.token)
              },
            }}
            variant={monthsLeft > 3 ? 'info' : 'danger'}
            key={renewal.id}
            title="Pensez à votre renouvellement"
          >
            <Text variant="body2">
              Votre RC Décennale arrivant à échéance dans {Math.round(monthsLeft)} mois, soit le{' '}
              {formatDdMmYyyy(renewal.effectDate!)}.<br />
              <br />
              Vous avez jusqu&apos;au{' '}
              {formatDdMmYyyy(substractDurationToCalendarDate(renewal.effectDate!, { months: 1 }, PARIS))} pour mettre à
              jour vos informations et éviter une pénalité de 50 %.
            </Text>
          </PersistentNotification>
        )
      })}
    </div>
  )
}
